import React from 'react'
import SEO from '../components/seo';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Tree from '../images/tree'
import Difference from '../components/Difference/index';

const AboutPage = () => {
    return (
        <>
        <SEO title="About" />
        <div className="">

                <section className=" container mx-auto p-5 flex flex-row md:pt-10 my-20">
                    <div className="flex-1">
                        <p className="font-extralight">About Us</p>
                        <p className="font-sans max-w-prose text-xl pb-10">
                            The Printman Publishers and Printers is a locally owned full service digital & commercial printing press in Peshawar with over 20 years of experience. Our printing press specializes in offset printing, digital printing, apparel printing, bindery, packaging, and distribution. From design to output, you can trust Printman to deliver great quality printing and signage on time, everytime.
                        </p>
                        {/* <Tree className="h-96"></Tree> */}
                    </div>
                    
                    {/* <div className="flex-1 bg-red-100">

                    </div> */}
            
            
            </section>

            <WhyWeStarted />

            <section className="container mx-auto p-5 flex flex-col lg:flex-row justify-between my-52 gap-8">
                <Difference number={1} title="The Quality Difference" subTitle="Our passion is to create beautiful and high-quality products. We deliver print materials that solve our client's needs using the highest industry standards." ></Difference>
                <Difference number={2} title="The Service Difference" subTitle="Available at any time, day or night. Our process is transparent, we meet every week with our clients to keep them updated on our progress." ></Difference>
                <Difference number={3} title="The Experience Difference" subTitle="We don't start until you are satisfied with how your product looks and feels. Heavy emphasis is given to prototyping and ideation." ></Difference>
            </section>

            
            {/* <WhyCalledMonss /> */}
        </div>
        </>
    )
}

export default AboutPage;



const WhyWeStarted = () => {

    const data = useStaticQuery(graphql`
        query AboutImageQuery {
            summerMountain: file(relativePath: { eq: "atta.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 500, maxHeight: 500, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
        }
    `)

    // if (!data?.placeholderImage?.childImageSharp?.fluid) {
    //     return <div>Picture not found</div>
    // }

    // return <Img className={`${props.className}`} fluid={data.placeholderImage.childImageSharp.fluid} />


    return (
        <section className="bg-monss text-white pt-24 lg:pt-28">
            <div className="container mx-auto p-5 flex flex-col-reverse lg:flex-row">
                <div className="flex-1 pr-3">
                    <p className="text-6xl">Our story</p>
                    <p className="max-w-md py-10 text-xl tracking-wide
                    leading-loose">
                    
                        The Printman Printers & Publishers was started by Atta's vision to deliver excellent services in Peshawar. He realized an opening in the market for complete binding and printing services. From there, word quickly grew of the Printman and he started working with companies from all over Pakistan. Today, The Printman has worked with clients from all over the world, and delivered quality products on time. The Printman now has a fully automated factory. With Atta, you will find his tenacity, passion and integrity embedded into everything that he does.
                    </p>
                </div>

                <div className="flex-1 flex flex-col justify-items-center justify-center content-center">
                    <Img fluid={data.summerMountain.childImageSharp.fluid} className="flex-1 flex-shrink-0 object-cover rounded-full " />
                    <p className='text-2xl'>Managing Director</p>
                    <p className='text-4xl'>Atta Ur Rehman</p>
                </div>


            </div>
        </section>
    )
}

